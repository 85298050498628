import React from 'react';
import { Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/MetaData';
import EBooksHero from '../../components/resources/EBooksHero';
import EBooksHub from '../../components/resources/EBooksHub';

const EBooks = () => {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/resources/ebookShareImage.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        }
        name
      }
    }
  `);

  return (
    <>
      <MetaData
        title='Nexton Ebooks'
        description='Improve your hiring techniques and stay competitive in your industry with our ebooks.'
        image={`https://www.nextonlabs.com${file.childImageSharp.gatsbyImageData.images.fallback.src}`}
      />
      <Layout selected='resources'>
        <Box className='full-width'>
          <EBooksHero />
        </Box>
        <EBooksHub />
      </Layout>
    </>
  );
};

export default EBooks;
