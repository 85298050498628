import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Box } from 'rebass';
import EBookCard from './EBookCard';

const MainContainer = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 90px;
  }
`;

const EBookContainer = styled.div`
  margin-top: 140px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0px;
`;

const MoreEBooksButton = styled.button`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 2px solid var(--secondary-orange-500-main, #ff6d00);
  background: var(--shade-white-0, #fff);
  color: var(--secondary-orange-500-main, #ff6d00);
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
`;

const EBooksHub = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityEbook(sort: { fields: _createdAt, order: ASC }) {
        nodes {
          ebookImage {
            asset {
              url: gatsbyImageData(
                width: 248
                height: 352
                placeholder: NONE
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          ebookImageAlt
          hubSubtitle
          hubTitle
          slug {
            current
          }
        }
      }
    }
  `);

  const [eBooks, setEBooks] = useState(data?.allSanityEbook?.nodes);
  const [visibleEBooks, setVisibleEBooks] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;

  const loadMoreEBooks = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newVisibleEBooks = [...visibleEBooks, ...eBooks.slice(startIndex, endIndex)];
    setVisibleEBooks(newVisibleEBooks);
    setPage(page + 1);
  };

  useEffect(() => {
    if (data) {
      setEBooks(data?.allSanityEbook?.nodes);
      loadMoreEBooks();
    }
  }, [data]);

  return (
    <Box>
      <MainContainer>
        {visibleEBooks?.map(eBook => (
          <EBookContainer>
            <EBookCard eBookProp={eBook} hub />
          </EBookContainer>
        ))}
      </MainContainer>
      <ButtonContainer>
        <MoreEBooksButton onClick={loadMoreEBooks} hidden={visibleEBooks.length === eBooks.length}>
          More E-Books <FontAwesomeIcon icon={faArrowRight} />
        </MoreEBooksButton>
      </ButtonContainer>
    </Box>
  );
};

export default EBooksHub;
