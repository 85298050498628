import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  height: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #00a5c8;
  background: var(--gradient-btn-1-active, linear-gradient(90deg, #00a5c8 0%, #01c8ef 100%));
  box-shadow: 0px 31px 43px -14px rgba(161, 161, 161, 0.46);
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    height: 332px;
    margin-bottom: 0px;
  }
`;

const GoBackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-left: 150px;

  @media (max-width: 1200px) {
    margin-left: 20px;
  }
`;
const Text = styled.span`
  color: var(--primary-blue-100, #ebf8fb);
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin-left: 7px;
`;

const HeroTitle = styled.h1`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -1px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;

  @media (max-width: 640px) {
    font-size: 42px;
    line-height: 60px;
    margin-top: 0px;
  }
`;

const HeroSubtitle = styled.h3`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;

  @media (max-width: 640px) {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 0px;
  }
`;

const EBooksHero = () => {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeroContainer>
      <GoBackContainer>
        <Link to='/resources'>
          <FontAwesomeIcon icon={faChevronLeft} size='lg' style={{ color: '#EBF8FB' }} />
          <Text hidden={windowWidth < 1000}>Back</Text>
        </Link>
      </GoBackContainer>
      {windowWidth < 640 ? (
        <HeroTitle>
          <strong>E-books</strong> to improve your hiring
        </HeroTitle>
      ) : (
        <HeroTitle>
          <strong>E-books</strong> and <strong>Reports</strong>
        </HeroTitle>
      )}
      <HeroSubtitle>Enhance your hiring strategies with exclusive access to expert insights</HeroSubtitle>
    </HeroContainer>
  );
};

export default EBooksHero;
