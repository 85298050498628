import React from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { Link as GatsbyLink } from 'gatsby';

const EBookContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.hub ? 'row' : 'column')};
  max-width: ${props => (!props.hub && !props.pageView ? '425px' : '1276px')};
  max-height: ${props => (props.hub ? '542px' : '738px')};
  border-radius: 24px;
  border: 1px solid var(--neutral-gray-200-strokes, #d0d0d0);
  background: var(--shade-white-0, #fff);

  @media (max-width: 1199px) {
    align-self: center;
    width: ${props => (!props.hub && !props.pageView ? '90%' : '100%')};
    max-width: ${props => props.hub && '400px'};
    max-height: ${props => props.hub && 'fit-content'};
    flex-direction: ${props => props.hub && 'column'};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  background: var(--primary-blue-100, #ebf8fb);
  width: ${props => (props.hub && !props.pageView ? '26%' : '100%')};
  min-height: 48%;
  border-top-left-radius: 24px;
  border-top-right-radius: ${props => (props.hub ? '0px' : '24px')};
  border-bottom-left-radius: ${props => (props.hub ? '24px' : '0px')};

  @media (max-width: 1199px) {
    width: ${props => props.hub && '100%'};
  }
`;
const EBookImage = styled.img`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  object-fit: contain;
  max-width: ${props => (props.hub ? '75%' : '65%')};
  max-height: ${props => (props.hub && !props.pageView ? '' : '80%')};
  border-radius: 4px;
  filter: drop-shadow(5px 5px 5px #4e4e50);
  padding: ${props => (props.hub ? '35px 0px' : '')};

  @media (max-width: 1199px) {
    width: 40%;
    padding: 25px 0px;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 380px;
  align-self: center;
  justify-content: space-evenly;

  @media (max-width: 377px) {
    width: 85%;
  }
`;

const ExcerptContainer = styled.div`
  max-height: 100%;
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #202323;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 28px;
    line-height: 38px;
  }

  h1::after,
  h2::after,
  h3::after {
    content: 'E-book';
    display: block;
    background-color: #00a5c815;
    color: #00a5c8;
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    margin: 8px 0px;
    padding: 0px 10px;
    width: fit-content;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-start;
`;

const Button = styled.button`
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 100px;
  background: var(--gradient-btn-1-active, linear-gradient(90deg, #00a5c8 0%, #01c8ef 100%));
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
`;

const HubTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  min-height: 360px;
  margin-left: 25px;
  align-self: center;
  justify-content: space-evenly;

  @media (max-width: 1199px) {
    min-height: 700px;
    width: 80%;
  }
`;
const HubTitle = styled.div`
  color: var(--shade-black-900, #202323);
  font-family: 'Rubik';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.5px;

  @media (max-width: 1199px) {
  }
`;
const HubSubtitle = styled.div`
  color: var(--shade-black-900, #202323);
  font-family: 'Rubik';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.5px;

  @media (max-width: 1199px) {
    font-size: 20px;
    line-height: 36px;
  }
`;

const EBookCard = ({ eBookProp, hub = false, pageView }) => {
  return (
    <EBookContainer hub={hub} pageView={pageView}>
      <ImageContainer hub={hub}>
        <EBookImage
          src={eBookProp?.ebookImage?.asset.url.images.fallback.src}
          alt={eBookProp?.ebookImageAlt}
          hub={hub}
        />
      </ImageContainer>
      {!hub ? (
        <TextContainer>
          <ExcerptContainer>
            <PortableText value={eBookProp?.excerpt} />
          </ExcerptContainer>
          <ButtonContainer>
            <GatsbyLink to={`/resources/ebook/${eBookProp?.slug.current}`}>
              <Button>View</Button>
            </GatsbyLink>
          </ButtonContainer>
        </TextContainer>
      ) : (
        <HubTextContainer>
          <HubTitle>{eBookProp?.hubTitle}</HubTitle>
          <HubSubtitle>{eBookProp?.hubSubtitle}</HubSubtitle>
          <ButtonContainer>
            <GatsbyLink to={`/resources/ebook/${eBookProp?.slug.current}`}>
              <Button>Download</Button>
            </GatsbyLink>
          </ButtonContainer>
        </HubTextContainer>
      )}
    </EBookContainer>
  );
};

export default EBookCard;
